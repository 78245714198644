import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"

const Greeting = () => (
    <Layout>
        <SEO title='Contact' />
        <h2 style={{ margin: '1em 0' }}>お問い合わせ / Contact</h2>
        <div className='Contact'>
            <form name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" data-netlify-recaptcha="true">
                <p>
                    <label>お名前 <br /><input type="text" name="name" /></label>
                </p>
                <p>
                    <label>メールアドレス <br /><input type="text" name="email" /></label>
                </p>
                <p>
                    <label>件名 <br /><input type='text' name='subject' />

                    </label>
                </p>
                <p>
                    <label>Don't fill out if ur human<input name='bot-field'></input></label>
                </p>
                <p>
                    <label>内容 <br /><textarea name="message"></textarea></label>
                </p>
                <div data-netlify-recaptcha="true"></div>
                <p>
                    <button type="submit">送信</button>
                </p>
            </form>
        </div>
    </Layout >
)

export default Greeting